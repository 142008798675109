import { Injectable } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { AlertTemplateComponent } from '../shared/alert-template/alert-template.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  timer: any;

  constructor(private location: Location, private router: Router, private spinner: NgxSpinnerService, private toastr: ToastrService, public dialog: MatDialog) { }

  downloadUrlAsPromise(url: any) {
    return new Promise<any>(function(resolve, reject) {
      var xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.responseType = "arraybuffer";
      xhr.onreadystatechange = function(evt) {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(xhr.response);
          } else {
            reject(new Error("Error for " + url + ": " + xhr.status));
          }
        }
      };
      xhr.send();
    });
  } 

  isMobileDevice() {
    return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
  }

  invalidRequest() {
    this.router.navigate(['/manage']);
    this.toastr.error('INVALID PAGE REQUESTED', 'Problem');
  }

  requestError(message: string, redirectTo: string) {
    //this.toastr.error(message, 'Problem');
    this.router.navigate([redirectTo]);
  }

  showSuccess(title: string, message: string) {
    const dialogRef = this.dialog.open(AlertTemplateComponent, {
      data: {
        iconType: 'success',
        iconPosition: 'overflow',
        title,
        text: message,
        button: 'OK'
      },
      autoFocus: false,
      panelClass: ['overflow-icon-alert-wrapper']
    });
  }

  showError(title: string, message: string) {
    const dialogRef = this.dialog.open(AlertTemplateComponent, {
      data: {
        iconType: 'error',
        iconPosition: 'overflow',
        title,
        text: message,
        button: 'OK'
      },
      autoFocus: false,
      panelClass: ['overflow-icon-alert-wrapper']
    });
  }

  showBusy(name?: string, delay?: number) {

    let spinnerName = 'loading-spinner';
    if (name) { spinnerName = name; }

    let spinnerDelay = 300;
    if (delay !== undefined) { spinnerDelay = delay; }

    this.timer = setTimeout(() => {
      this.spinner.show(spinnerName);
    }, spinnerDelay);
  }

  hideBusy(name?: string) {
    let spinnerName = 'loading-spinner';
    if (name) { spinnerName = name; }

    clearTimeout(this.timer);
    this.spinner.hide(spinnerName);
  }  

  showToastError(title: string, message: string) {
    this.toastr.error(message, title);
  }  

  showToastWarning(title: string, message: string) {
    this.toastr.warning(message, title);
  }    

  showToastSuccess(title: string, message: string) {
    this.toastr.success(message, title);
  }    

  showNetError() {
    this.toastr.error('There was a network problem, please ensure you are connected to the internet and try again', 'Problem', { progressBar: true });
  }
  

//   showBusy(name?: string, delay?: number) {

//     let spinnerName = 'main';
//     if (name) { spinnerName = name; }

//     let spinnerDelay = 300;
//     if (delay !== undefined) { spinnerDelay = delay; }

//     this.timer = setTimeout(() => {
//       this.spinner.show(spinnerName);
//     }, spinnerDelay);
//   }

//   hideBusy(name?: string) {
//     let spinnerName = 'main';
//     if (name) { spinnerName = name; }

//     clearTimeout(this.timer);
//     this.spinner.hide(spinnerName);
//   }

  resetForm(formGroup: FormGroup) {
    let control: AbstractControl = null;
    formGroup.reset();
    formGroup.markAsUntouched();
    Object.keys(formGroup.controls).forEach((name) => {
      control = formGroup.controls[name];
      control.setErrors(null);
    });
  }

  makeRandom(lengthOfCode: number) {
    // tslint:disable-next-line: quotemark
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890";

    let text = '';
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  addCommas(val: string) {
    // add commas to numerical strings - used for display purposes
    val += '';
    const x = val.split('.');
    let x1 = x[0];
    const x2 = x.length > 1 ? '.' + x[1] : '';
    const rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
  }

  documentToDomainObject = _ => {
    const object = _.payload.doc.data();
    object.id = _.payload.doc.id;
    return object;
  }  

  distance(lat1, lon1, lat2, lon2) {
    var p = 0.017453292519943295;    // Math.PI / 180
    var c = Math.cos;
    var a = 0.5 - c((lat2 - lat1) * p)/2 + c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))/2;
  
    //return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
    return (12742 * Math.asin(Math.sqrt(a))) / 0.6237;
  }  

  getWeek(dowOffset: number, date: Date) {
    // dowOffset is the day of week the week
    // "starts" on for your locale - it can be from 0 to 6. If dowOffset is 1 (Monday)    
   
    let newYear = new Date(date.getFullYear(), 0, 1);
    let day = newYear.getDay() - dowOffset;
    day = (day >= 0 ? day : day + 7);
    let daynum = Math.floor((date.getTime() - newYear.getTime() - (date.getTimezoneOffset() - newYear.getTimezoneOffset()) * 60000) / 86400000) + 1;

    let weeknum;

    if(day < 4) {
      weeknum = Math.floor((daynum + day -1 ) / 7) + 1;
      if(weeknum > 52) {
        let nYear = new Date(date.getFullYear() + 1, 0, 1);
        let nday = nYear.getDay() - dowOffset;
        nday = nday >= 0 ? nday : nday + 7;
        weeknum = nday < 4 ? 1 : 53;
      }
    }
    else {
      weeknum = Math.floor((daynum + day -1 ) / 7);
    }
    return weeknum;
  };  

  getFileExtension(filename: string) {
    const splittedDoc = filename.split('.');
    return splittedDoc[splittedDoc.length - 1];
    // this.selectedViewer = this.viewers.find(v => v.name === viewerName);
    //   this.selectedDoc = this.selectedViewer.docs[0];
  }
}


