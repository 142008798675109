export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyCSqELX5p-weH-xVW9jkLvB-rDQU1tJPRg",
    authDomain: "app-crm-pb.firebaseapp.com",
    databaseURL: "https://app-crm-pb.firebaseio.com",
    projectId: "app-crm-pb",
    storageBucket: "app-crm-pb.appspot.com",
    messagingSenderId: "499884709392",
    appId: "1:499884709392:web:7d89cbd6331076b8b1dc95",
    measurementId: "G-M829HW5249"
  }
};
