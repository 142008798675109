import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormGroupDirective } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { SharedService } from '../../shared/shared.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {

  signinForm: FormGroup;

  validationMessages = {
    email: [
      { type: 'required', message: 'Email is required.' }
    ],
    password: [
      { type: 'required', message: 'Password is required.' }
    ]
  };  

  constructor(
    public router: Router,
    private fb: FormBuilder,
    private authenticationService: AuthenticationService,
    private sharedService: SharedService

  ) { }

  ngOnInit() {
    this.signinForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  onSignin(formDirective: FormGroupDirective) {
    const data = this.signinForm.value;

    this.sharedService.showBusy();

    this.authenticationService.login(data.email, data.password)
    .then((user) => {
      this.sharedService.hideBusy();
      this.router.navigate(['dashboards']);
    })
    .catch((err) => {
      this.sharedService.hideBusy();
      if (err && err.code == 'auth/user-disabled') {
        this.sharedService.showToastError('Problem', err.message);  
      } else{
        this.sharedService.showToastError('Problem', 'The email or password you entered was incorrect.');
      }

      formDirective.resetForm();
    });
  }

}
