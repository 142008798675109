<div class="side-menu-branding">
  <div class="branding-logo">
    <cc-image-shell animation="spinner" [src]="'/assets/imgs/logo/kisreclogo.png'" [alt]="'KIDSSREC'"></cc-image-shell>
  </div>
</div>

<ul class="side-menu-navigation nav flex-column">
  <li class="menu-item expandable-item nav-item" [routerLinkActive]="['active']">
    <mat-expansion-panel [expanded]="false">
      <mat-expansion-panel-header class="menu-link">
        <div class="item-header-content">
          <mat-panel-title>
            <mat-icon class="menu-item-icon">dashboard</mat-icon>
            <span class="menu-item-title">Dashboards</span>
          </mat-panel-title>
        </div>
      </mat-expansion-panel-header>
      <ul class="menu-sub-navigation nav flex-column">

        <li class="menu-item nav-item" [routerLinkActive]="['active']">
          <a class="menu-link nav-link" [routerLinkActive]="['active']" [routerLink]="['/dashboards/main']">
            <i class="menu-item-icon"></i>
            <span class="menu-item-title">Main</span>
          </a>
        </li>
        
      </ul>
    </mat-expansion-panel>
  </li>

  <li class="menu-item expandable-item nav-item" [routerLinkActive]="['active']">
    <mat-expansion-panel [expanded]="false">
      <mat-expansion-panel-header class="menu-link">
        <div class="item-header-content">
          <mat-panel-title>
            <mat-icon class="menu-item-icon">supervised_user_circle</mat-icon>
            <span class="menu-item-title">Manage</span>
          </mat-panel-title>
        </div>
      </mat-expansion-panel-header>
      <ul class="menu-sub-navigation nav flex-column">

        <li class="menu-item nav-item" [routerLinkActive]="['active']">
          <a class="menu-link nav-link" [routerLinkActive]="['active']" [routerLink]="['/manage/agency']">
            <i class="menu-item-icon"></i>
            <span class="menu-item-title">Agency</span>
          </a>
        </li>
        
      </ul>
    </mat-expansion-panel>
  </li>  

  
</ul>
