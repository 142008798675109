import { Injectable } from '@angular/core';
import { MapsAPILoader } from "@agm/core";

@Injectable({
  providedIn: 'root'
})
export class GeoCodeService {

  private geoCoder;
  private distanceService;

  constructor(private maps: MapsAPILoader,) { 
    this.maps.load().then(() => {
        this.geoCoder = new google.maps.Geocoder();
        this.distanceService = new google.maps.DistanceMatrixService();
    });
  }

  getAddress(postCode: string) {
    return new Promise((resolve, reject) => {
        this.geoCoder = new google.maps.Geocoder();
        this.geoCoder.geocode({ 'address': postCode, componentRestrictions: { country: "GB" } }, (results, status) => {
          if(status === 'OK') {
            resolve(results);
          } else {
            console.log(results);
            console.log(status);

            if (status === 'ZERO_RESULTS') {
              reject({ message: 'The Post Code appears to be incorrect, please check and try again.'})                  
            } else {
              reject({ message: 'Unable to validate post code at this time, please try again later.'})
            }
          }
          this.geoCoder = null;
        }, (err) => {
          console.log(err);
          reject({ message: 'Unable to validate post code at this time, please try again later.'})
        });
    });
  }

  getDistance(latStart: any, lonStart: any, latEnd: any, lonEnd: any) {
    return new Promise((resolve, reject) => {
        
      const matrixOptions = {
        origins: [new google.maps.LatLng(latStart, lonStart)],
        destinations: [new google.maps.LatLng(latEnd, lonEnd)],
        travelMode: 'DRIVING',
        unitSystem: google.maps.UnitSystem.IMPERIAL
      };

      this.distanceService.getDistanceMatrix(matrixOptions, (results, status) => {
        if(status === 'OK') {
          resolve(results);
        } else {
          results(null);
        }
      })

    });
  }
}
