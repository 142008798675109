<div class="row no-gutters signin-page">
  <div class="col-md-7 col-lg-6 col-sm-12 form-section">

    <div class="d-flex flex-row align-items-center">
      <div class="login-logo mt-5">
        <cc-image-shell animation="spinner" [src]="'/assets/imgs/logo/kisreclogo.png'" [alt]="'KIDSSREC'"></cc-image-shell>
      </div>
  
      <div class="ml-4 page-title">
        <span>Admin</span> 
      </div>
    </div>


    <!-- <h1 class="page-title">BMR CRM</h1> -->
    <span class="page-subtitle">
      Enter your sign in details below.
    </span>
    <form class="signin-form" [formGroup]="signinForm" #formDirective="ngForm" (ngSubmit)="onSignin(formDirective)" novalidate>
      <div class="form-group">
        <label for="email">Email</label>
        <input type="email" class="form-control" id="email" formControlName="email">
        <mat-error *ngFor="let validation of validationMessages.email">
          <mat-error
              *ngIf="signinForm.get('email').hasError(validation.type) && (signinForm.get('email').dirty || signinForm.get('email').touched)">
              {{validation.message}}</mat-error>
        </mat-error>        
      </div>
      <div class="form-group">
        <label for="password">Password</label>
        <input type="password" class="form-control" id="password" formControlName="password">
        <mat-error *ngFor="let validation of validationMessages.password">
          <mat-error
              *ngIf="signinForm.get('password').hasError(validation.type) && (signinForm.get('password').dirty || signinForm.get('password').touched)">
              {{validation.message}}</mat-error>
        </mat-error>              
      </div>
      <div class="form-call-to-actions">
        <button class="sign-in-btn" type="submit" [disabled]="!signinForm.valid">Sign In</button>
      </div>
    </form>
  </div>

</div>
